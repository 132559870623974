// Import React
import React, {useState, useEffect} from 'react';

// Import gatsby
import {navigate} from "gatsby"

// Import Firebase
import getFirebase from '../utils/firebase';

// Import Material UI
import Grow from '@material-ui/core/Grow';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
// import FormControl from '@material-ui/core/FormControl';
// import FormHelperText from '@material-ui/core/FormHelperText'
// import Input from '@material-ui/core/Input'
// import InputLabel from '@material-ui/core/InputLabel';

// Import components
import Layout from "../components/layout";
import SEO from "../components/seo"

// Make Styles
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '',
    minHeight: '98vh',
    marginTop: '1vh',
    width: '99vw',
    padding: '5vh 8vw'
  },
  title: {
    textAlign: 'center',
    fontWeight: '700',
    fontFamily: 'Work Sans',
    padding: '0px 20px',
    fontSize: '3.5vw',
    lineHeight: '1.1',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '5vw',
      padding: '0px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '8vw',
      padding: '0px'
    }
  },
  description: {
    fontFamily: 'Work Sans',
    textAlign: 'center',
    fontWeight: '300',
    lineHeight: '1.1',
    fontSize: '2.8vw',
    padding: '0.5vw 3vw',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '3.5vw',
      padding: '0.5vw 2vw',
      // padding: '5px 5px'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '5.5vw',
      padding: '1vw 3vw'
    }
  },
  credit: {
    fontFamily: 'Work Sans',
    textAlign: 'center',
    fontWeight: '200',
    lineHeight: '1.3',
    fontSize: '2vw',
    padding: '12px 20px 5px',
    color: '#6e6e6e',
    [
      theme
        .breakpoints
        .down('sm')
    ]: {
      fontSize: '3vw'
    },
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      fontSize: '4.5vw'
    }
  },
  dialog: {
    padding: '40px',
    textAlign: 'center',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      padding: '20px'
    }
  },
  button: {
    margin: '8px',
    [
      theme
        .breakpoints
        .down('xs')
    ]: {
      margin: '5px'
    }
  }
}))

// Transitions
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props}/>;
});

// Get Firebase
const firebase = getFirebase();

function capitalizeFirstLetter(string) {
  return string
    .charAt(0)
    .toUpperCase() + string.slice(1);
}

// Login Dialog
function UnlockDialog(props) {
  const [submitError, setSubmitError] = useState(false);
  const [errorText, setErrorText] = useState(undefined);
  const [inputText, setInputText] = useState(undefined);

  const classes = useStyles();
  const {onClose, open} = props;

  const handleClose = () => {
    onClose();
    setSubmitError(false)
    setErrorText(undefined)
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (inputText === '2020') {
      // console.log('Success')
      const db = firebase.firestore();
      db
        .collection("users").doc(props.activeUser.uid).set({uid: props.activeUser.uid, email: props.activeUser.email, first: props.first, last: props.last, photoURL: props.activeUser.photoURL, joinDate: Date(), favourites: []});
      navigate('/welcome');
    } else {
      // console.log('Failure')
      setSubmitError(true);
      setErrorText("Oops, that's not right.");
    }
  };
  function handleChange(event) {
    setInputText(event.target.value);
  }

  return (<Dialog onClose={handleClose} aria-labelledby="betakey-dialog-title" open={open} TransitionComponent={Transition}>
    <div className={classes.dialog}>
      <DialogTitle>Join the Private Beta</DialogTitle>
      <form onSubmit={handleSubmit}>
        <Grid container="container" direction='column' justify="space-evenly" alignItems="center">
          <TextField onChange={handleChange} error={submitError} helperText={errorText} required="required" id="beta-key" label="My Beta Key" variant='outlined' color="primary" style={{
              // margin: '15px'
            }}/>
          <Button type="submit" size="large" color="secondary" variant='contained' startIcon={<VpnKeyIcon />} style={{
              marginTop: '20px'
            }}>Unlock</Button>
        </Grid>
      </form>
    </div>
  </Dialog>);
}

// Page
function SignedIn() {

  // Use Styles
  const classes = useStyles();

  // State
  const [activeUser, setActiveUser] = useState(undefined);
  const [firstName, setFirstName] = useState(undefined);
  const [lastName, setLastName] = useState(undefined);
  const [greeting, setGreeting] = useState('');
  const [photoURL, setPhotoURL] = useState(undefined);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  // Effect Hook
  useEffect(() => {
    if (!firebase)
      return;
    firebase
      .auth()
      .onAuthStateChanged((user) => {
        if (user) {
          setActiveUser(user);
          if (activeUser) {
            setPhotoURL(activeUser.photoURL);
            let userFirstName = activeUser
              .displayName
              .substring(0, activeUser.displayName.indexOf(" "))
            setGreeting(`Thanks for your interest, ${userFirstName}.`);
            userFirstName = capitalizeFirstLetter(userFirstName)
            setFirstName(userFirstName);
            let userLastName = activeUser
              .displayName
              .substring(activeUser.displayName.indexOf(" "))
            userLastName = capitalizeFirstLetter(userLastName)
            setLastName(userLastName)
          }
        } else {
          setActiveUser(false)
        }
      });
  });

  // Functions
  const handleSignOut = () => {
    firebase
      .auth()
      .signOut();
    navigate('/signedout')
  };
  const openUnlockDialog = () => {
    setDialogIsOpen(true);
  };
  const closeDialog = value => {
    setDialogIsOpen(false);
  };

  // Conditionals
  const profileImage = (
    photoURL
    ? <img src={photoURL} alt='Profile' width='150px' height='150px' style={{
        borderRadius: '150px',
        marginBottom: '-2px'
      }}/>
    : undefined)

  // Components
  const title = (<Grid item="item">
    <Typography variant='h3' className={classes.title}>{greeting}</Typography>
  </Grid>)
  const description = (<Grid item="item">
    <Typography variant='h4' className={classes.description}>Beta access is currently by invitation only.</Typography>
    <Typography variant='h4' className={classes.description}>I'll be in touch soon with more details.</Typography>
    <Typography variant='h4' className={classes.credit}>Cheers,<br/>Solomon</Typography>
  </Grid>)
  const buttons = (<Grid item="item">
    <Grid container="container" justify="space-evenly" alignItems="center">
      <Button size='large' color="primary" variant='contained' onClick={openUnlockDialog} className={classes.button}>Enter Beta Key</Button>
      <Button size='large' color="primary" variant='outlined' onClick={handleSignOut} className={classes.button}>Sign Out</Button>
    </Grid>
  </Grid>)

  // Final Page
  const loading = <Grid container="container" direction="column" justify="center" alignItems="center" className={classes.root}>
    <CircularProgress/>
  </Grid>
  const loadedPage = <Grid container="container" className={classes.root}>
    <Grid container="container" direction="column" justify="space-evenly" alignItems="center" spacing={3}>
      {title}
      {profileImage}
      {description}
      {buttons}
    </Grid>
  </Grid>

  // Render
  return (<Layout>
    <SEO title="Signed In"/>
    <Grow in="in">
      {
        profileImage
          ? loadedPage
          : loading
      }
    </Grow>
    <UnlockDialog activeUser={activeUser} first={firstName} last={lastName} open={dialogIsOpen} onClose={closeDialog}/>
  </Layout>)
}

export default SignedIn
